import * as statusMapper from "@/service/error_request_mapper.js";

export class EmergencyLevelTicketingService {
  constructor(api) {
    this.api = api;
  }

  async getAll() {
    return this.api.getAll()
    .then((data) => {
      data.sort(function(a, b) {
        return a.label.localeCompare(b.label);
      });
      return data;
    })
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  async getById(id) {
    return this.api
      .getById(id)

      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(id);
        converter.convert(error);
      });
  }

  async create(entity) {
    return this.api.create(entity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.id);
      converter.add409AlreadyExists(entity.label);
      converter.convert(error);
    });
  }

  async update(entity) {
    return this.api.update(entity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.id);
      converter.convert(error);
    });
  }

  async delete(id) {
    return this.api.delete(id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(id);
      converter.convert(error);
    });
  }
}
